<template>
  <a-config-provider :locale="locale">
    <div class="page-main-view">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script setup>
  import { ref } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  dayjs.locale('zh-cn');
  const locale = ref(zhCN)

</script>

<style lang="less">
  body {
    background-color: #f1f1f1;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    position: relative;
  }
  .page-main-view {
    width: 100vw;
    height: 100vh;
    position: relative;
  }
</style>
