import { createStore } from 'vuex'
import { login, getInfo } from '@/api/login-service'
import { onJsDecryptData } from '@/utils/common'
import  pcaData  from 'china-division/dist/pca.json';
export default createStore({
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    level: '',
    userId: '',
    roles: [],
    info: {},
    cityData: [],
  },
  getters: {
    getToken: (state) => {
      return state.token;
    },
    getUserLevel: (state) => {
      return state.level;
    },
    getUserName: (state) => {
      return state.name;
    },
    getUserID: (state) => {
      return state.userId;
    },
    getUserHead: (state) => {
      return state.avatar;
    },
    getCityData: (state) => {
      return state.cityData;
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_INFO: (state, info) => {
      state.info = info
      state.level = info.type;
      state.userId = info.id;
      state.name = info.nickname;
      state.avatar = info.avatar;
    },
    SET_CITY_DATA: (state, data) => {
      state.cityData = data;
    },
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response) {
            if (response.message) {
              reject(new Error(response.message))
            } else {
              if (response.data) {
                const jsonResult = onJsDecryptData(response.data)
                commit('SET_TOKEN', jsonResult.access_token)
                localStorage.setItem("user-limit", jsonResult.type);
                localStorage.setItem("Access-Token", jsonResult.access_token);
                resolve()
              } else {
                reject(response)
              }
            }
          } else {
            reject(response)
          }
        }).catch(error => {
          console.log(error)
          reject(new Error(error.response.data.message))
        })
      })
    },
    // 设置用户信息
    setUserInfo ({ commit }, userInfo) {
      commit('SET_INFO', userInfo)
    },
    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          if (response) {
            const result = response.data
            commit('SET_INFO', result)
            localStorage.setItem("user-limit", result.type);
            resolve()
          }else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取省市区数据
    getCityData ({ commit, state }) {
      let newList = [];
      for (const key in pcaData) {
        const element = pcaData[key];
        const dataList = {
          value: key,
          label: key,
          children: [],
        }
        for (const newKey in element) {
          const childrenData = element[newKey];
          const itemData = {
            value: newKey,
            label: newKey,
            children: [],
          }
          for (let index = 0; index < childrenData.length; index++) {
            itemData.children.push({
              value: childrenData[index],
              label: childrenData[index],
            })
          }
          dataList.children.push(itemData);
        }
        newList.push(dataList)
      }
      commit('SET_CITY_DATA', newList)
    },
    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        commit('SET_INFO', '');
        localStorage.setItem('Access-Token', '');
        localStorage.setItem('user-limit', 0);
        resolve()
      })
    }
  },
  modules: {}
})