
const strJsEncrypt = require('crypto-js')
const strIv = 'zV4YPXq6nJ9RC2wy'
const strKey = 'g5ahPqZKGfXmt6R0'

/*
* aes加密
*/
export function onJsEncryptData (strData) {
	var vals = strData
	var options = {
		iv: strJsEncrypt.enc.Utf8.parse(strIv),
		mode: strJsEncrypt.mode.CBC,
		padding: strJsEncrypt.pad.Pkcs7
	}
	var key = strJsEncrypt.enc.Utf8.parse(strKey)
	var decrypteData = strJsEncrypt.AES.encrypt(vals, key, options)
	var decrypteDataStr = decrypteData.toString()
	return decrypteDataStr
}
/*
* aes解密
*/
export function onJsDecryptData (strData) {
	var vals = strData
	var options = {
		iv: strJsEncrypt.enc.Utf8.parse(strIv),
		mode: strJsEncrypt.mode.CBC,
		padding: strJsEncrypt.pad.Pkcs7
	}
	var key = strJsEncrypt.enc.Utf8.parse(strKey)
	var decrypteData = strJsEncrypt.AES.decrypt(vals, key, options)
	var decrypteStr = strJsEncrypt.enc.Utf8.stringify(decrypteData)
	var strObject = JSON.parse(decrypteStr)
	return strObject
}
