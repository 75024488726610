import HomeView from '../views/HomeView.vue'
/**
 * 基础路由
 * @type { *[] }
 */
export const addRouterMap = [
  {
    path: '/login',
    name: 'login',
    meta: { 
      requireAuth: false,
      title: '登录'
    },
    roles: ['superAdmin', 'admin'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: { requireAuth: true },  // 是否校验登录
    component: HomeView,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        roles: ['superAdmin','admin'],
        component: () => import('@/views/dashboard.vue'),
        meta: { title: 'dashboard', requireAuth: true, isChild: false, roles: ['superAdmin', 'admin'] },
      },
      {
        path: '/userList',
        name: 'userList',
        roles: ['superAdmin','admin'],
        component: () => import('@/views/userManage/userList.vue'),
        meta: { title: '用户管理', requireAuth: true, isChild: false, roles: ['superAdmin', 'admin'] },
      },
      {
        path: '/advertisingList',
        name: 'advertisingList',
        roles: ['superAdmin','admin'],
        component: () => import('@/views/advertisingManage/advertisingList.vue'),
        meta: { title: '广告管理', requireAuth: true, isChild: false, roles: ['superAdmin', 'admin'] },
      },
      {
        path: '/couponList',
        name: 'couponList',
        roles: ['superAdmin','admin'],
        component: () => import('@/views/couponManage/couponList.vue'),
        meta: { title: '优惠券管理', requireAuth: true, isChild: false, roles: ['superAdmin', 'admin'] },
      },
      {
        path: '/luckyPointList',
        name: 'luckyPointList',
        roles: ['superAdmin','admin'],
        component: () => import('@/views/luckyPointManage/luckyPointList.vue'),
        meta: { title: '幸运值明细', requireAuth: true, isChild: false, roles: ['superAdmin', 'admin'] },
      },
      {
        path: '/freeGoodsManage',
        name: 'freeGoodsManage',
        roles: ['superAdmin', 'admin'],
        meta: { title: '0元购管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'admin'] },
        children: [
          {
            path: '/freeGoodsManage/freeGoodsList',
            name: 'freeGoodsList',
            component: () => import('@/views/freeGoodsManage/freeGoodsList.vue'),
            meta: { title: '0元购', requireAuth: true, }
          },
          {
            path: '/freeGoodsManage/freeGoodsAuditList',
            name: 'freeGoodsAuditList',
            component: () => import('@/views/freeGoodsManage/freeGoodsAuditList.vue'),
            meta: { title: '0元购审核', requireAuth: true, }
          },
          {
            path: '/freeGoodsManage/helpProblemList',
            name: 'helpProblemList',
            component: () => import('@/views/freeGoodsManage/helpProblemList.vue'),
            meta: { title: '助力问题', requireAuth: true, }
          },
        ]
      },
      {
        path: '/activityManage',
        name: 'activityManage',
        roles: ['superAdmin', 'admin'],
        meta: { title: '活动管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'admin'] },
        children: [
          {
            path: '/activityManage/signInList',
            name: 'signInList',
            component: () => import('@/views/activityManage/signInList.vue'),
            meta: { title: '每日签到', requireAuth: true, }
          },
          {
            path: '/activityManage/luckyPointExchange',
            name: 'luckyPointExchange',
            component: () => import('@/views/activityManage/luckyPointList.vue'),
            meta: { title: '幸运值兑换', requireAuth: true, }
          },
        ]
      },
      {
        path: '/welfareManage',
        name: 'welfareManage',
        roles: ['superAdmin', 'admin'],
        meta: { title: '抽选管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'admin'] },
        children: [
          {
            path: '/welfareManage/welfareList',
            name: 'welfareList',
            component: () => import('@/views/welfareManage/welfareList.vue'),
            meta: { title: '抽选列表', requireAuth: true, }
          },
          {
            path: '/welfareManage/auditList',
            name: 'auditList',
            component: () => import('@/views/welfareManage/auditList.vue'),
            meta: { title: '抽选审核列表', requireAuth: true, }
          },
        ]
      },
      {
        path: '/goodsManage',
        name: 'goodsManage',
        roles: ['superAdmin', 'admin'],
        meta: { title: '盲盒商品管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'admin'] },
        children: [
          {
            path: '/goodsManage/blindBoxList',
            name: 'blindBoxList',
            component: () => import('@/views/goodsManage/blindBoxList.vue'),
            meta: { title: '盲盒系列', requireAuth: true, }
          },
          {
            path: '/goodsManage/goodsList',
            name: 'goodsList',
            component: () => import('@/views/goodsManage/goodsList.vue'),
            meta: { title: '盲盒商品', requireAuth: true, }
          },
        ]
      },
      {
        path: '/collectionManage',
        name: 'collectionManage',
        roles: ['superAdmin', 'admin'],
        meta: { title: '孵化间管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'admin'] },
        children: [
          {
            path: '/collectionManage/collectionCreator',
            name: 'collectionCreator',
            component: () => import('@/views/collectionManage/collectionCreator.vue'),
            meta: { title: '发起人列表', requireAuth: true, }
          },
          {
            path: '/collectionManage/collectionProject',
            name: 'collectionProject',
            component: () => import('@/views/collectionManage/collectionProject.vue'),
            meta: { title: '项目列表', requireAuth: true, }
          },
          {
            path: '/collectionManage/collectionGoods',
            name: 'collectionGoods',
            component: () => import('@/views/collectionManage/collectionGoods.vue'),
            meta: { title: '商品列表', requireAuth: true, }
          },
          {
            path: '/collectionManage/collectionUpdate',
            name: 'collectionUpdate',
            component: () => import('@/views/collectionManage/collectionUpdate.vue'),
            meta: { title: '项目更新列表', requireAuth: true, }
          },
        ]
      },
      {
        path: '/financeManage',
        name: 'financeManage',
        roles: ['superAdmin'],
        meta: { title: '订单管理', requireAuth: true, isChild: true, roles: ['superAdmin'] },
        children: [
          {
            path: '/financeManage/collectionOrder',
            name: 'collectionOrder',
            component: () => import('@/views/financeManage/collectionOrder.vue'),
            meta: { title: '众筹订单', requireAuth: true, }
          },
          {
            path: '/financeManage/payOrderList',
            name: 'payOrderList',
            component: () => import('@/views/financeManage/payOrderList.vue'),
            meta: { title: '支付订单', requireAuth: true, }
          },
          {
            path: '/financeManage/sendOrderList',
            name: 'sendOrderList',
            component: () => import('@/views/financeManage/sendOrderList.vue'),
            meta: { title: '发货订单详情', requireAuth: true, }
          },
          {
            path: '/financeManage/sendCollectionList',
            name: 'sendCollectionList',
            component: () => import('@/views/financeManage/sendCollectionList.vue'),
            meta: { title: '众筹发货详情', requireAuth: true, }
          },
        ]
      },
      {
        path: '/accountList',
        name: 'accountList',
        roles: ['superAdmin'],
        component: () => import('@/views/accountManage/accountList.vue'),
        meta: { title: '账户管理', requireAuth: true, isChild: false, roles: ['superAdmin'] },
      },
      {
        path: '/logList',
        name: 'logList',
        roles: ['superAdmin'],
        component: () => import('@/views/logManage/logList.vue'),
        meta: { title: '日志管理', requireAuth: true, isChild: false, roles: ['superAdmin']  },
      },
    ]
  }
]
