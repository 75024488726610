import request from '@/utils/request'

const apiUrl = {
  Login: 'login',
  UserInfo: 'admin/info',
  UserList: 'admin/list',
  addUser: 'admin/add',
  deleteUser: 'admin/delete',
  editUser: 'admin/edit',

  dashboard: 'dashboard'
}
export function getDashboardData (parameter) {
  return request({
    url: apiUrl.dashboard,
    method: 'get',
    data: parameter
  })
}
// 用户登录
export function login (parameter) {
  return request({
    url: apiUrl.Login,
    method: 'post',
    data: parameter
  })
}
// 获取用户信息
export function getInfo () {
  return request({
    url: apiUrl.UserInfo,
    method: 'get'
  })
}
// 获取用户列表
export function getUserListData (parameter) {
  return request({
    url: apiUrl.UserList,
    method: 'post',
    data: parameter
  })
}
// 新增用户
export function addUser (parameter) {
  return request({
    url: apiUrl.addUser,
    method: 'post',
    data: parameter
  })
}
// 删除用户
export function deleteUser (parameter) {
  return request({
    url: apiUrl.deleteUser,
    method: 'post',
    data: parameter
  })
}
// 编辑用户
export function editUser (parameter) {
  return request({
    url: apiUrl.editUser,
    method: 'post',
    data: parameter
  })
}
